import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const TextImage = styled(Plaatjie)`
  border-radius: 25px;
  overflow: hidden;
  transform: translateZ(0);

  @media (min-width: 992px) {
    position: absolute !important;
    left: 0;
    right: 0;
    top: -25px;
    bottom: -25px;
  }

  @media (max-width: 575px) {
    position: relative;
    bottom: -25px;
    z-index: 1;
  }
`

const TextContent = styled.div<{ mirror: boolean }>`
  background-color: ${(props) =>
    props.mirror ? props.theme.color.primary : props.theme.color.dark};
  position: relative;
  display: flex;
  align-items: center;

  & p:last-child {
    font-size: ${({ theme }) => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
  }

  &:after {
    background-color: ${(props) =>
      props.mirror ? props.theme.color.primary : props.theme.color.dark};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2000px;

    @media (min-width: 992px) {
      ${(props) =>
        props.mirror
          ? css`
              right: 100%;
            `
          : css`
              left: 100%;
            `}
    }

    @media (max-width: 991px) {
      left: 100%;
    }
  }

  @media (min-width: 575px) {
    min-height: 350px;
  }

  @media (max-width: 992px) {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  @media (max-width: 575px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 my-lg-5">
      <div className="row py-sm-4">
        <div
          className={`col-sm-6 position-relative px-lg-0 order-lg-${
            fields.mirror ? '3' : '1'
          }`}
        >
          <TextImage image={fields.image} alt="VT Tuinen" />
        </div>
        <TextContent
          mirror={!!fields.mirror}
          className={`col-sm-6 py-md-5 ps-4 ${
            fields.mirror ? 'pe' : 'ps'
          }-lg-5 order-lg-1`}
        >
          <ParseContent
            className="ps-xl-5 py-5 mt-sm-0 mt-4 py-md-4"
            content={fields.description}
          />
        </TextContent>
      </div>
    </div>
  </section>
)

export default Text
