import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div
      className={`container ${
        fields.jointop ? 'pb-5 mb-lg-5' : 'py-5 my-lg-5'
      }`}
    >
      <ParseContent content={fields.description} />
    </div>
  </section>
)

export default Text
