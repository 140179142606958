import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section className={fields.spacing === 'much' ? 'py-lg-4' : ''}>
    <div className="container py-5 my-lg-5">
      <div className="row">
        <div className={`col-sm-5 col-lg-${fields.widthcolumnleft}`}>
          <ParseContent content={fields?.descriptionleft} />
        </div>
        <div
          className={`col-sm-7 col-lg-${
            12 - (fields.widthcolumnleft || 4)
          } mt-4 mt-sm-0`}
        >
          <ParseContent content={fields?.description} />
        </div>
      </div>
    </div>
  </section>
)

export default Text
