import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const TextContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    margin-bottom: 2.5rem;
  }

  & p:last-child {
    margin-top: 2rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @media (min-width: 992px) {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: 200px 200px;
  }

  @media (max-width: 991px) {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 150px 150px;
  }
`

const Col = styled.div`
  &:nth-child(1) {
    grid-column: span 5;
  }

  &:nth-child(2) {
    grid-column: span 7;
  }

  &:nth-child(3) {
    grid-column: span 7;
  }

  &:nth-child(4) {
    grid-column: span 5;
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="row">
        <div className="col-md-6 pe-md-5">
          <TextContent className="pe-xl-5" content={fields.description} />
        </div>
        <div className="col-md-6 mt-5 mt-md-0">
          <Grid>
            {fields.gallery?.map((media, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={index}>
                <Plaatjie
                  className="w-100 h-100"
                  image={media}
                  alt="VT Tuinen"
                />
              </Col>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  </section>
)

export default Text
