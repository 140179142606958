import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Media
import Leaf from 'img/leaf.inline.svg'

const LeafWrapper = styled.div`
  position: absolute;
  right: calc(100% + 15px);
  top: 0;
`

const TextContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    margin-bottom: 2.5rem;
  }

  & p:last-child {
    margin-top: 2rem;
  }
`

const TextBlock = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  border: 2px solid ${({ theme }) => theme.color.light};

  &:hover {
    border: 2px solid ${({ theme }) => theme.color.primary};

    & h3 {
      text-decoration: underline;
    }
  }

  @media (min-width: 992px) {
    height: 250px;
  }

  @media (max-width: 991px) {
    height: 200px;
  }

  @media (max-width: 575px) {
    height: 150px;
  }
`

const TextBlockIcon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 75px;
    height: 75px;
  }

  @media (max-width: 992px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 575px) {
    width: 35px;
    height: 35px;
  }
`

const TextBlockTitle = styled.h3`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.dark};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.huge};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.big};
  }

  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.font.size.medium};
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="position-relative">
        <LeafWrapper>
          <Leaf />
        </LeafWrapper>
      </div>
      <div className="row">
        <div className="col-md-6 pe-lg-5">
          <TextContent className="pe-lg-5" content={fields.description} />
        </div>
        <div className="col-md-6 mt-5 mt-lg-0">
          <div className="row">
            {fields.icons?.map((edge, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="col-6 mb-4">
                <TextBlock to={edge?.link?.url || ''}>
                  <div className="text-center px-3">
                    <TextBlockIcon
                      image={edge?.icon}
                      alt={edge?.link?.title || ''}
                    />
                    <TextBlockTitle className="mt-4 mt-sm-5">
                      {parse(edge?.link?.title || '')}
                    </TextBlockTitle>
                  </div>
                </TextBlock>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Text
